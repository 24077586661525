import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    document.title = "Tyvra"; // Set your desired title here
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Coming Soon!</h1>
        <img src="images\tyvra-logo.png" className="App-logo" alt="logo" />
      </header>

      {/* Footer Section */}
      <footer className="App-footer">
        <p>&copy; 2024 Tyvra. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
